import axios from 'axios';
import { Toast } from "vant";

const service = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 5000,
    headers: {
        // 设置后端需要的传参类型
        'Content-Type': 'application/json',
        // 'token': 'your token',
        'X-Requested-With': 'XMLHttpRequest',
    },
})
Toast.allowMultiple();
let toast1 
service.interceptors.request.use(
    (config) => {
        // const token = Vue.ls.get(ACCESS_TOKEN)
        // if (token) {
        //   config.headers[ 'X-Access-Token' ] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
        // }
        toast1 = Toast.loading({
            duration:0,
            message: '加载中...',
            forbidClick: true,
        });
        return config
    }
)
service.interceptors.response.use(
    (res) => {
        toast1.clear()
        console.log('res', res);
        if (res.data.code == 200) {
            return res.data.result
        } else {
            Toast.fail({
                duration:2000,
                message: res.data.message,
                forbidClick: true,
            });
        }
    },
    (err) => {
        console.log(err);
        Toast.fail({
            duration: 2000,
            message: err.message,
            forbidClick: true,
        });
        return err
    }
)

export default service